






















































import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useRoute,
  useFetch, computed,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { CmsPage } from '~/modules/GraphQL/types';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent, useConfig } from '~/composables';
import HTMLContent from '~/components/HTMLContent.vue';
import SfImage from '/storefrontUI/components/components/atoms/SfImage/SfImage.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    HTMLContent,
    SfImage,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const route = useRoute();
    const currentLang = route.value.matched[0].path;
    const { addTags } = useCache();
    const { loadPage, loadBlocks } = useContent();
    const { app } = useContext();
    const { isDesktop } = app.$device;
    const { config } = useConfig();
    const banner = [];

    const page = ref<CmsPage | null>(null);
    const blocks = ref();
    const bannerLeftTop = ref();
    const bannerLeftBottom = ref();
    const bannerCenter = ref();
    const bannerRightTop = ref();
    const bannerRightBottom = ref();
    const bannerFull = ref();
    const bannerDonna = ref();
    const bannerUomo = ref();
    const textHome = ref();

    const url = route.value.fullPath;

    const ipx = computed(() => {
      if(isDesktop) {
        return '460';
      }
      else{
        return '380';
      }
    });

    const ipxCenter = computed(() => {
      if(isDesktop) {
        return '960';
      }
      else{
        return '380';
      }
    });

    const logoSrc = computed(() => {
      const baseMediaUrl = config.value.base_media_url;
      const logo = config.value.header_logo_src;

      return baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';
    });


    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
      if(currentLang == '/it'){
        blocks.value =  await loadBlocks({identifiers : ['bannerLeftTopIt', 'bannerLeftBottomIt', 'bannerCenterIt', 'bannerRightTopIt', 'bannerRightBottomIt', 'bannerFullIt', 'bannerDonnaIt', 'bannerUomoIt']});
        textHome.value =  await loadBlocks({identifiers : ['textHomeIt']});
      }
      else {
        blocks.value =  await loadBlocks({identifiers : ['bannerLeftTopEn', 'bannerLeftBottomEn', 'bannerCenterEn', 'bannerRightTopEn', 'bannerRightBottomEn', 'bannerFullEn', 'bannerDonnaEn', 'bannerUomoEn']});
        textHome.value =  await loadBlocks({identifiers : ['textHomeEn']});
      }

      textHome.value = textHome.value[0].content;

      if (Array.isArray(blocks.value)) {
        blocks.value.forEach(function (element) {
          let image = element.content.match(/<img class="pagebuilder-mobile-hidden" src="(.*?)"+/g);
          let mobile = element.content.match(/<img class="pagebuilder-mobile-only" src="(.*?)"+/g);
          let href = element.content.match(/<a href="(.*?)"+/g);

          image = image[0].replace('<img class="pagebuilder-mobile-hidden" src="', '');
          image = image.replace('"', '');


          mobile = mobile[0].replace('<img class="pagebuilder-mobile-only" src="', '');
          mobile = mobile.replace('"', '');

          href = href[0].replace('<a href="', '');
          href = href.replace('"', '');
          banner.push({href: href, image: image, mobile: mobile});
        });
      }

      bannerLeftTop.value = banner[0]
      bannerLeftBottom.value = banner[1];
      bannerCenter.value = banner[2];
      bannerRightTop.value = banner[3];
      bannerRightBottom.value = banner[4];
      bannerFull.value = banner[5];
      bannerDonna.value = banner[6];
      bannerUomo.value = banner[7];

      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    onMounted(() => {

    });

    // @ts-ignore
    return {
      page,
      blocks,
      url,
      logoSrc,
      bannerLeftTop,
      bannerLeftBottom,
      bannerCenter,
      bannerRightTop,
      bannerRightBottom,
      bannerFull,
      bannerDonna,
      bannerUomo,
      textHome,
      isDesktop,
      ipx,
      ipxCenter,
    };
  },
  head() {
    return getMetaInfo(this.page, false, this.logoSrc, 'https://www.needstore.it' + this.url, '', 'home');
  }
});
